@mixin breakpoint($point) {
  @if $point==sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point==max-sm {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $point==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point==max-md {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point==lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point==max-lg {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point==xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point==max-xl {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $point==xxl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $point==max-xxl {
    @media (max-width: 1399px) {
      @content;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

:root {
  --base: #0e9384;
  --base-2: #13548b;
  --base-3: #f9f871;
  --white: #ffffff;
  --body: #ffffff;
  --section: #f9fafb;
  --title: #101828;
  --text: #475467;
  --border: #d0d5dd;
}

body {
  background: var(--body);
  font-family: "Montserrat", sans-serif;
  color: var(--text);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title);
  font-weight: 600;
}

h1 {
  font-size: 60px;
  line-height: 72px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
h2 {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
}
h3 {
  font-size: 30px;
  line-height: 38px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 20px;
}
@include breakpoint(max-md) {
  h1 {
    font-size: 42px;
    line-height: 52px;
  }
  h2 {
    font-size: 28px;
    line-height: 42px;
  }
  h3 {
    font-size: 24px;
    line-height: 34px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 20px;
  }
}
@include breakpoint(max-sm) {
  h1 {
    font-size: 32px;
    line-height: 42px;
  }
  h2 {
    font-size: 24px;
    line-height: 36px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
}
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
  transition: all ease 0.3s;
}
.font-light {
  font-weight: 300 !important;
}
.font-regular {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-extra-bold {
  font-weight: 800 !important;
}
.font-black {
  font-weight: 900 !important;
}
.text-base {
  color: var(--base);
}
@include breakpoint(xl) {
  .container {
    max-width: 1240px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.bg-section {
  background: var(--section);
}
.bg-body {
  background: var(--body);
}
.cmn-btn {
  border-radius: 24px;
  font-family: "Montserrat", sans-serif;
  background: var(--base);
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding: 12px 20px;
  color: #ffffff;
  transition: all ease 0.3s;
  border: 1px solid var(--base);
  display: inline-block;
  &:hover {
    border-color: var(--base);
    color: var(--base);
    background: var(--white);
  }
  @include breakpoint(max-sm) {
    font-weight: 500;
    font-size: 14px;
    padding: 5px 14px;
    height: unset;
  }
  &.bg-white {
    border-color: var(--white);
    color: var(--title);
    &:hover {
      color: var(--white);
      background: var(--base) !important;
      border-color: var(--base) !important;
    }
  }
  &.modal-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 44px;
    min-width: 155px;
  }
  &[disabled] {
    background: #f2f2f2;
    color: #80807f;
    border-color: #f2f2f2;
    box-shadow: 0px 0px 24px rgba(5, 5, 5, 0.05);
  }
  &.btn-lg {
    @include breakpoint(sm) {
      font-size: 22px;
      line-height: 1.5;
      padding: 12px 34px;
    }
  }
}
.rounded-4px {
  border-radius: 4px;
}
/*Header*/
.header-wrapper {
  padding: 18px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  .logo {
    display: block;
    img {
      height: 3rem;
    }
    margin-right: 3rem;
    // @include breakpoint(lg) {
    // 	margin-right: 24px;
    // }
    // @include breakpoint(xl) {
    // 	margin-right: 32px;
    // }
  }
  .login {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--title);
    font-family: "Montserrat", sans-serif;
    padding: 5px;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: var(--base);
    }
    @include breakpoint(max-xl) {
      font-size: 14px;
      font-weight: 400;
    }
    @include breakpoint(max-lg) {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .menu-area {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    .menu {
      display: flex;
      align-items: center;
      column-gap: 1.5rem;
      // margin-left: auto;
      @include breakpoint(lg) {
        margin-right: 15px;
      }
      li {
        a {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
          color: var(--title);
          font-family: "Montserrat", sans-serif;
          padding: 5px;
          &:hover,
          &.active {
            color: var(--base);
          }
        }
      }
    }
    .cmn-btn {
      padding: 8px 17px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 24px;
      font-size: 16px;
      font-weight: 400;
      min-width: 95px;
      text-align: center;
      &:hover {
        background: transparent;
      }
      &.btn-outline {
        color: var(--title);
        background: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    }
    @include breakpoint(max-xl) {
      .menu {
        li {
          a {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    .close {
      display: none;
    }
    @include breakpoint(max-lg) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 340px;
      height: 100vh;
      background: var(--section);
      z-index: 999;
      flex-direction: column;
      padding: 50px 20px 30px;
      justify-content: flex-start;
      align-items: flex-start;
      transition: all ease 0.4s;
      .close {
        position: absolute;
        right: 15px;
        top: 10px;
        width: unset;
        display: block;
      }
      > * {
        width: 100%;
      }
      .search-form {
        margin: 0;
        flex-grow: 0;
        .form-control {
          height: 46px;
          border-radius: 24px;
        }
      }
      .menu {
        width: 100%;
        flex-direction: column;
        // margin: 45px 0;
        gap: 6px;
        li {
          width: 100%;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          a {
            display: block;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      &:not(.active) {
        transform: translateX(-100%);
      }
    }
  }
  .bar {
    display: none;
    @include breakpoint(max-lg) {
      display: block;
    }
  }
  @include breakpoint(max-md) {
    padding-right: 12px;
  }
}
.header-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  @include breakpoint(max-lg) {
    .cmn-btn {
      flex-grow: 1;
    }
  }
}
header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--body);
  border-bottom: 1px solid #f2f4f7;
  transition: all ease 0.6s;
}
/*Footer Section*/
.footer-bottom {
  padding: 1rem 0 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  border-top: 1px solid #eaecf0;
  @include breakpoint(sm) {
    justify-content: space-between;
  }
  .logo {
    display: block;
    max-width: 150px;
    img {
      width: 100%;
    }
  }
  @include breakpoint(max-md) {
    padding-bottom: 60px;
  }
}
.footer-top {
  padding: 14rem 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  .footer-widget {
    width: calc(100% / 3 - 20px);
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 18px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        a {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--base);
        }
      }
    }
  }
  @include breakpoint(max-md) {
    padding-bottom: 30px;
    .footer-widget {
      width: 160px;
      flex-grow: 1;
      ul {
        li {
          a {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.section-gap {
  padding: 20px 0;
  @include breakpoint(max-md) {
    padding: 60px 0;
  }
}
.cta-section {
  background: var(--base-2);
}
.cta-wrapper {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
  .title {
    margin-bottom: 20px;
  }
  .text {
    margin-bottom: 24px;
    @include breakpoint(md) {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 40px;
    }
  }
  .btn-wrapper {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .cmn-btn {
    padding: 11px 20px;
    line-height: 24px;
    &:hover {
      border-color: var(--white);
    }
    @include breakpoint(max-sm) {
      font-size: 14px;
      line-height: 22px;
      padding: 8px 15px;
    }
  }
}
.section-title {
  h5 {
    margin-bottom: 12px;
    color: var(--base);
  }
  h2 {
    margin-bottom: 20px;
  }
  @include breakpoint(md) {
    font-size: 20px;
    line-height: 1.5;
  }
  margin-bottom: 64px;
  @include breakpoint(max-lg) {
    margin-bottom: 40px;
  }
  @include breakpoint(max-md) {
    margin-bottom: 30px;
  }
}
@include breakpoint(xl) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1254px;
  }
}
.feature-item {
  display: flex;
  flex-wrap: wrap;
  .content {
    width: 0;
    flex-grow: 1;
    .subtitle {
      margin-top: 10px;
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 20px;
    }
    a {
      font-size: 16px;
      font-weight: 600;
      display: inline-flex;
      gap: 8px;
    }
    @include breakpoint(max-xl) {
      p {
        margin-bottom: 10px;
      }
    }
  }
  @include breakpoint(max-sm) {
    .icon {
      width: 40px;
      svg {
        width: 100% !important;
      }
    }
    .content {
      .subtitle {
        margin-top: 4px;
      }
    }
  }
}
.feature-wrapper {
  gap: 48px;
  @include breakpoint(max-xl) {
    gap: 30px;
  }
}
.why-card-1 {
  background: var(--section);
  padding: 24px;
  .icon {
    margin-bottom: 38px;
  }
  .title {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 40px;
  }
  a {
    font-size: 16px;
    line-height: 24px;
    color: var(--base);
    font-weight: 600;
    display: inline-flex;
    gap: 8px;
  }
  @include breakpoint(max-lg) {
    padding: 18px;
    .icon {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 24px;
    }
  }
}
.partner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 145px;
  row-gap: 15px;
  @include breakpoint(max-md) {
    column-gap: 95px;
    img {
      max-width: 100%;
    }
  }
  @include breakpoint(max-sm) {
    column-gap: 35px;
    img {
      max-width: 100%;
    }
  }
}
.trending-business {
  padding: 49px 0 59px;
  position: relative;
  .container {
    @include breakpoint(xl) {
      max-width: 1286px;
    }
  }
  .pattern-2 {
    position: absolute;
    top: -70px;
    // left: calc(50% + 500px);
    right: 200px;
    @media (max-width: 1750px) {
      right: 100px;
    }
    @media (max-width: 1500px) {
      right: 40px;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
  }
}
.cmn-badge {
  display: inline-flex;
  background: var(--base);
  padding: 8px 16px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  gap: 4px;
  border-radius: 8px 8px 8px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    clip-path: polygon(0 0, 100% 100%, 100% 0);
    width: 8px;
    height: 8px;
    background: var(--base);
  }
}
.trending-card {
  position: relative;
  background: #fff;
  a {
    display: block;
    color: var(--title);
  }
  img {
    width: 100%;
    border-radius: 8px 8px 0 0;
    max-height: 198px;
    min-height: 198px;
  }
  .cmn-badge {
    position: absolute;
    left: -8px;
    transform: translateY(50%);
    bottom: 0;
  }
  background: var(--body);
  border-radius: 8px;
  &-content {
    border-radius: 0 0 8px 8px;
    border-top: none;
    padding: 32px 24px 0;
    border: 1px solid #f0effb;
    .content {
      padding-bottom: 16px;
      border-bottom: 1px solid #f0effb;
      display: flex;
      justify-content: space-between;
      .price {
        font-weight: 800;
        color: var(--base);
        margin-bottom: 4px;
      }
      .title {
        font-weight: 700;
        margin-bottom: 8px;
        a {
          &:hover {
            color: var(--base);
          }
        }
      }
      font-size: 16px;
      font-weight: 500;
      .location {
        opacity: 0.5;
      }
    }
  }
  &-footer {
    padding-top: 8px;
    color: #000929;
    opacity: 0.7;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin-bottom: 10px;
    .info {
      font-weight: 500;
      font-size: 14px;
    }
    .value {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
    }
    > div {
      width: 50%;
    }
  }
}
.trending-card-wrapper {
  @include breakpoint(xl) {
    column-gap: 33px;
    > div {
      max-width: 376px;
    }
    @media (min-width: 1250px) {
      column-gap: 40px;
    }
    @media (min-width: 1280px) {
      column-gap: 70px;
    }
    @media (min-width: 1320px) {
      column-gap: 75px;
    }
  }
}
.partner-banner {
  background: rgba(14, 147, 132, 0.2);
  padding: 64px 0 144px;
  font-size: 20px;
  line-height: 1.5;
  @include breakpoint(xl) {
    .container {
      max-width: 1262px;
    }
  }
  @include breakpoint(max-lg) {
    padding: 50px 0;
  }
}
.partner-banner-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  .content {
    max-width: 640px;
    width: 100%;
    .title {
      margin-bottom: 24px;
    }
  }
  .img {
    width: 506px;
    flex-grow: 1;
  }
  @include breakpoint(max-xl) {
    .content {
      .title {
        font-size: 48px;
        line-height: 1.2;
      }
      max-width: 540px;
    }
    .img {
      width: 340px;
    }
  }
  @include breakpoint(max-lg) {
    text-align: center;
    justify-content: center;
    .content {
      margin: 0 auto;
    }
    .img {
      width: 100%;
      max-width: 380px;
    }
  }
  @include breakpoint(max-md) {
    .content {
      .title {
        font-size: 36px;
      }
    }
  }
  @include breakpoint(max-sm) {
    .content {
      font-size: 18px;
      .title {
        font-size: 32px;
      }
    }
  }
}
.our-partners {
  padding: 62px 0 27px;
  @include breakpoint(max-lg) {
    padding-bottom: 0;
  }
}
.single-partner {
  background: var(--section);
  .logo {
    margin-bottom: 38px;
  }
  h6 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 40px;
  }
  .cmn-btn {
    min-height: 48px;
    min-width: 169px;
    display: inline-block;
    text-align: center;
  }
}
.partner-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 64px;
  background: var(--section);
  gap: 25px;
  .content {
    width: 0;
    flex-grow: 1;
    @include breakpoint(max-md) {
      width: 100%;
    }
  }
  @include breakpoint(max-lg) {
    padding: 50px 30px;
  }
  @include breakpoint(max-md) {
    padding: 30px 20px;
  }
  @include breakpoint(md) {
    font-size: 20px;
    line-height: 30px;
  }
}
.broker-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;

  --base: var(--base-2);

  .content {
    width: 0;
    flex-grow: 1;
    @include breakpoint(max-md) {
      width: 100%;
    }
  }
  @include breakpoint(md) {
    font-size: 20px;
    line-height: 30px;
  }
  .cmn-btn {
    &.bg-white {
      border-color: var(--border);
    }
    @include breakpoint(sm) {
      line-height: 22px;
    }
  }
  .btn-grp {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
  }
}
.broker-cta-section {
  @include breakpoint(max-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.sponsor-wrapper {
  padding: 64px;
  @include breakpoint(max-lg) {
    padding: 50px;
  }
  @include breakpoint(max-md) {
    padding: 40px 30px;
  }
  @include breakpoint(max-sm) {
    padding: 40px 20px;
  }
}
.sponsor-img {
  padding: 0 11px;
  width: 222px;
  @include breakpoint(max-md) {
    width: 160px;
    padding: 0 5px;
  }
}
.feature-card {
  .icon {
    margin-bottom: 20px;
  }
  .title {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 20px;
  }
  a {
    color: var(--base);
    display: inline-flex;
    align-items: center;
    gap: 8px;
    svg {
      path {
        stroke: var(--base);
      }
    }
  }
}
.convert-base-2 {
  --base: var(--base-2);
}
/*Pricing Section*/
.pricing-top {
  padding-top: 96px;
  background: rgba(#0e9384, 0.2);
  @include breakpoint(max-sm) {
    padding-top: 70px;
  }
}
.pricing-section-title {
  text-align: center;
  padding-bottom: 44px;
  .cate {
    padding: 4px 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    color: #000000;
    background: rgba(14, 147, 132, 0.1);
    mix-blend-mode: multiply;
    border-radius: 16px;
    margin-bottom: 16px;
  }
  .title {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 24px;
  }
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  @include breakpoint(max-sm) {
    .title {
      font-size: 36px;
      line-height: 1.3;
    }
    font-size: 18px;
  }
}
.pricing-bg-img {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 1000px;
  top: 0;
}
.pricing-bottom {
  .container {
    position: relative;
    // z-index: 3;
  }
}
.pricing-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  .icon {
    margin-bottom: 20px;
    svg {
      width: 46px;
    }
  }
  .name {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--base);
    margin-bottom: 8px;
  }
  .price {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
  }
  ul {
    padding: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 16px;
    li {
      display: flex;
      gap: 12px;
      span {
        width: 0;
        flex-grow: 1;
      }
    }
  }
  .btn-area {
    padding: 32px;
    margin-top: auto;
  }
  padding-top: 32px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 24px;
  @include breakpoint(xl) {
    margin-left: 4px;
    margin-right: 4px;
  }
  @include breakpoint(max-xl) {
    @include breakpoint(lg) {
      padding-top: 25px;
      .price {
        font-size: 40px;
        margin-bottom: 10px;
      }
      .icon {
        margin-bottom: 15px;
      }
      .name {
        margin-bottom: 10px;
      }
      .btn-area,
      ul {
        padding: 24px;
      }
    }
  }
  @include breakpoint(max-md) {
    padding-top: 25px;
    max-width: 350px;
    margin: 0 auto;
    .price {
      font-size: 40px;
      margin-bottom: 10px;
    }
    .icon {
      margin-bottom: 15px;
    }
    .name {
      margin-bottom: 10px;
    }
    .btn-area,
    ul {
      padding: 24px;
    }
  }
}
/*Banner SEction*/
.banner-section {
  overflow: hidden;
  position: relative;
  .pattern {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}
.banner-wrapper {
  padding: 6rem 2rem 2rem;
  border-radius: 24px;
  // background: var(--base);
  position: relative;
  @include breakpoint(max-lg) {
    padding: 70px 30px 100px;
  }
  @include breakpoint(max-md) {
    padding: 40px 30px 70px;
  }
  @include breakpoint(max-sm) {
    padding: 40px 20px;
  }
}
.banner-content {
  max-width: 969px;
  margin: 0 auto;
  .content {
    // max-width: 768px;
    text-align: center;
    margin: 0 auto;
    .title {
      font-size: 72px;
      color: var(--white);
      line-height: 90px;
      margin-bottom: 24px;
    }
    font-size: 20px;
    line-height: 1.5;
    color: var(--white);
    p {
      margin-bottom: 48px;
    }
    @include breakpoint(max-lg) {
      .title {
        font-size: 60px;
        line-height: 1.2;
      }
      font-size: 18px;
    }
    @include breakpoint(max-md) {
      .title {
        font-size: 40px;
        line-height: 1.2;
      }
      font-size: 16px;
      p {
        margin-bottom: 24px;
      }
    }
    @include breakpoint(max-sm) {
      .title {
        font-size: 32px;
      }
      font-size: 14px;
      p {
        margin-bottom: 24px;
      }
    }
  }
}
.dropdown-area {
  display: flex;
  background: var(--white);
  padding: 17px 22px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  .dropdown--item {
    position: relative;
    &:not(:last-child) {
      &::after {
        height: 41px;
        width: 1px;
        background: #e0def7;
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
    &:first-child {
      width: 33%;
      padding: 0 43px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      padding: 0 43px;
      margin-right: 43px;
    }
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: rgba(0, 22, 25, 0.7);
      h5,
      input {
        font-weight: 700;
        font-size: 18px;
        line-height: 145%;
        color: #000929;
        margin: 0;
        height: unset;
        border: none;
        outline: none;
        box-shadow: none;
        max-width: 100%;
        padding-right: 15px;
      }
    }
    ul {
      display: block;
      @include breakpoint(md) {
        min-width: 220px;
      }
      padding: 6px 0;
      li {
        padding: 6px 12px;
        transition: all ease 0.3s;
        font-size: 14px;
        cursor: pointer;
        &:hover,
        &.active {
          background: #0e938335;
        }
      }
    }
    a:not(:focus) ~ ul {
      opacity: 0;
      visibility: hidden;
    }
  }
  @include breakpoint(max-lg) {
    .dropdown--item {
      &:first-child {
        width: 160px;
        padding-left: 3px;
      }
      &:nth-child(2) {
        width: 160px;
        padding: 0 18px;
        margin-right: 18px;
      }
      a {
        font-size: 14px;
        h5 {
          font-size: 16px;
        }
      }
    }
  }
  @include breakpoint(max-md) {
    .dropdown--item {
      &:first-child {
        width: 50%;
        padding-left: 3px;
        &::after {
          display: none;
        }
      }
      &:nth-child(2) {
        width: 50%;
        padding: 0;
        margin-right: 0;
        &::after {
          display: none;
        }
      }
      a {
        @include breakpoint(max-sm) {
          font-size: 13px;
          h5 {
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    .btn-area {
      width: 100%;
      padding-top: 20px;
      .cmn-btn {
        width: 100%;
      }
    }
  }
}
.userLinksNonMobile {
  @media (min-width: 992px) {
    display: none;
  }
}
.userAvatarMobile {
  @media (min-width: 992px) {
    display: none;
  }
}
.userAvatarNonMobile {
  @media (max-width: 991px) {
    display: none;
  }
}

.listingDetails-btn {
  height: 56px;
  // width: 100%;
  border-radius: 24px;
  background: var(--base);
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding: 12px 20px;
  color: #ffffff;
  transition: all ease 0.3s;
  border: 1px solid var(--base);
  display: inline-block;
  &:hover {
    border-color: var(--base);
    color: var(--base);
    background: var(--white);
  }
  @include breakpoint(max-sm) {
    font-weight: 500;
    font-size: 14px;
    padding: 5px 14px;
    height: unset;
  }
  &.bg-white {
    border-color: var(--white);
    color: var(--title);
    &:hover {
      color: var(--white);
      background: var(--base) !important;
      border-color: var(--base) !important;
    }
  }
  &.modal-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 44px;
    min-width: 155px;
  }
  &[disabled] {
    background: #f2f2f2;
    color: #80807f;
    border-color: #f2f2f2;
    box-shadow: 0px 0px 24px rgba(5, 5, 5, 0.05);
  }
  &.btn-lg {
    @include breakpoint(sm) {
      font-size: 22px;
      line-height: 1.5;
      padding: 12px 34px;
    }
  }
}

.listingDetails-btn-grey {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 24px;
  background: var(--text);
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  padding: 12px 20px;
  color: #ffffff;
  transition: all ease 0.3s;
  border: 1px solid var(--text);
  display: inline-block;
  &:hover {
    border-color: var(--text);
    color: var(--text);
    background: var(--white);
  }
  @include breakpoint(max-sm) {
    font-weight: 500;
    font-size: 14px;
    padding: 5px 14px;
    height: unset;
  }
  &.bg-white {
    border-color: var(--white);
    color: var(--title);
    &:hover {
      color: var(--white);
      background: var(--text) !important;
      border-color: var(--text) !important;
    }
  }
  &.modal-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 44px;
    min-width: 155px;
  }
  &[disabled] {
    background: #f2f2f2;
    color: #80807f;
    border-color: #f2f2f2;
    box-shadow: 0px 0px 24px rgba(5, 5, 5, 0.05);
  }
  &.btn-lg {
    @include breakpoint(sm) {
      font-size: 22px;
      line-height: 1.5;
      padding: 12px 34px;
    }
  }
}
