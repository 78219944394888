.listed-by-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    /* padding: 5px; */
  }
  
  .listed-by-link:hover, .listed-by-link:active{
    color: #0e9384;
  }