.cardSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.cardDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardDiv span {
  color: #0e9384;
}

.benefitsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.benefitsList li {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.carousel-card {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 3rem;
  border-radius: 30px;
  background: #f6f6f6;
  gap: 1rem;
}

.carouselBtn {
  border-radius: 48px;
  background: var(--base);
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  padding: 12px 20px;
  color: #ffffff;
  transition: all ease 0.3s;
  border: 1px solid var(--base);
  display: inline-block;
  --dsbtn: var(--white);
}

.carouselBtn:hover {
  --dsbtn: var(--base);
  background: var(--white);
  cursor: pointer;
}

.activeCard {
  background: #0e9384;
  color: white;
}

.activeCardText {
  color: white;
}

.resultNumbersCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
  width: 33%;
  text-align: center;
}

.backgroundImage {
  margin-top: 3rem;
  width: 100%;
  background-image: url("../../assets/DealScout/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.chip {
  display: flex;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 1rem;
  background: #e0f8de;
  width: 15rem;
  color: #004642;
}

.cardImg {
  width: 40%;
}

.mailinput {
  border: 0;
  border-radius: 10px;
  padding: 20px;
  border-radius: 60px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 8px 25px 0px rgba(13, 10, 44, 0.06);
  width: 100%;
}

.mailinput:focus {
  outline: none;
}

.mailinput::placeholder {
  color: rgb(0, 0, 0);
}

.mailButton {
  border: 0;
  padding: 15px;
  border-radius: 56px;
  background: var(--Theme-Color, #0e9384);
  box-shadow: 0px 2px 23px 0px rgba(0, 0, 0, 0.25);
}

.pt-site-footer__submit {
  position: relative;
  display: inline-block;
  width: 33%;
}

.industryGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
}

.industryGridList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.industryGridItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.industryGridItem div {
  max-width: 100%;
}

.industryGridItem svg {
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
}
.actionText {
  color: white;
}

.listingDetails-btn:hover .actionText {
  color: #0e9384;
}

.links-img {
  max-width: 75%;
  text-align: center;
  margin: 4rem 0;
}

.links-img img {
  max-width: 100%;
  height: auto;
}

.header {
  transition: background 0.3s ease;
}

.transparent {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0);
}
