#forgotPassword{
    font-size: "1vw";
    color: grey;
}

#signUp{
    margin-top: "8px";
    font-size: "1vw";
    color: grey;
}

@media (max-Width: 768px) {
    #forgotPassword, #signUp {
        font-size: "5vw";
    }
}