#alreadyHaveAccount{
    margin-top: "8px";
    font-size: "1vw";
    color: grey;
}

@media (max-Width: 768px) {
    #alreadyHaveAccount {
        font-size: "5vw";
    }
}