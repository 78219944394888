.checkout-page-container {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
}

.checkout-header {
  text-align: center;
  margin-bottom: 2rem;
}

.checkout-products-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;
}

.checkout-form-button {
  margin: 1rem 3rem 0;
  align-self: center;
}

.checkout-stripe-container {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  border: solid 3px var(--base);
  border-radius: 8px;
  gap: 3rem;
  width: 100%;
}
