@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

h1 {
  font-size: 3.75rem;
  font-style: bold;
  font-family: "Montserrat", sans-serif;
}
h2 {
  font-size: 2.5rem;
  font-style: bold;

  font-family: "Montserrat", sans-serif;
}
h3 {
  font-size: 2rem;
  font-style: bold;
  margin: 0;

  font-family: "Montserrat", sans-serif;
}
h4 {
  font-size: 1.5rem;
  font-style: bold;
  margin: 0;

  font-family: "Montserrat", sans-serif;
}
h5 {
  font-size: 1rem;
  font-style: bold;
  margin: 0;

  font-family: "Montserrat", sans-serif;
}
p {
  font-family: "Montserrat", sans-serif;
  color: black;
  margin: 0;
}

span {
  font-family: "Montserrat", sans-serif;
  color: black;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* pop us is at top */
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.text {
  color: #272b30;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
}

.textLink {
  color: #518e6e;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
}

.textLink:hover {
  color: #518e6e;
  text-decoration: underline;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
  cursor: pointer;
}

.deal-scout-link,
.deal-scout-link:hover {
  color: #518e6e;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
}

.deal-scout-span-link,
.deal-scout-span-link:hover {
  color: #518e6e;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
  cursor: pointer;
}

.add-to-list-button {
  top: 210px;
  left: 1107px;
  width: 144px;
  height: 34px;
  padding: 0px 8px;
  border: 1px solid #030303;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  color: #030303;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.add-to-list-button:hover {
  top: 210px;
  left: 1107px;
  width: 144px;
  height: 34px;
  padding: 0px 8px;
  border: none;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #518e6e;
  color: #ffffff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.remove-from-list-button {
  top: 210px;
  left: 1107px;
  width: 144px;
  height: 34px;
  padding: 0px 8px;
  border: 1px solid #518e6e;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #518e6e;
  color: #ffffff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.remove-from-list-button:hover {
  top: 210px;
  left: 1107px;
  width: 144px;
  height: 34px;
  padding: 0px 8px;
  border: none;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #030303;
  color: #ffffff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.add-to-campaign-button {
  top: 210px;
  left: 1107px;
  width: 147.5px;
  height: 34px;
  padding: 0px 8px;
  border: 1px solid #030303;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  color: #030303;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.add-to-campaign-button:hover {
  top: 210px;
  left: 1107px;
  width: 147.5px;
  height: 34px;
  padding: 0px 8px;
  border: none;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #518e6e;
  color: #ffffff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.remove-from-campaign-button {
  top: 210px;
  left: 1107px;
  width: 147.5px;
  height: 34px;
  padding: 0px 8px;
  border: 1px solid #518e6e;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #518e6e;
  color: #ffffff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.remove-from-campaign-button:hover {
  top: 210px;
  left: 1107px;
  width: 147.5px;
  height: 34px;
  padding: 0px 8px;
  border: none;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #030303;
  color: #ffffff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 18px;
  outline: none;
}

.detail-page-icon {
  color: #0e9384;
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 0.5rem;
}

.detail-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.detail-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: auto;
}

.detail-list-item-title {
  font-weight: 600;
  max-width: 33%;
}
