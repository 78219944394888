@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');

.faqLink{
    font-family: 'Plus Jakarta Sans:wght@500', sans-serif;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    color: #0e9384;
}

.faqLink:hover {
    text-decoration: underline;
}