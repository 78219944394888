.returnHome{
    color: #0e9384;
    text-decoration: none;
}

.returnHome:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .pageNotFoundImage {
      width: 100%;
    }
  }