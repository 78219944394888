.product-name-container {
  display: flex;
  flex-direction: row;
  align-self: start;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-name {
  font-weight: bold;
  font-size: 2rem;
  margin: 0px;
  height: 45px;
  line-height: 45px;
}

.popular-badge {
  border-radius: 20px;
  background-color: white;
  color: var(--base);
  font-weight: normal;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
}

.product-price-container {
  display: flex;
  flex-direction: row;
  align-self: start;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.product-price {
  color: var(--base);
  font-weight: bold;
  font-size: 3rem;
}

.product-description {
  line-height: 1.5rem;
  margin: 0;
  font-size: 87.5%;
}

.growth {
  color: white;
}

.line-separator {
  border: solid 1px darkgray;
  width: 100%;
  margin: 0;
}

.product-features-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  font-size: 1rem;
}

.product-feature {
  display: flex;
  gap: 0.5rem;
  word-break: break-word;
  font-size: 87.5%;
}

.product-button-container {
  display: flex;
  gap: 1rem;
  margin-top: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.product-button,
.non-checkout-button {
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 20px;
}

.growth-button {
  background-color: white;
  color: var(--base);
  border-radius: 24px;
}

.growth-button:not([disabled]):hover {
  background: var(--base);
  border-color: white;
  color: white;
}
